import React, { Fragment } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  possibleCurrencies: string[];
  darkMode?: boolean;
}

export default function CurrencyToggle({ className, value, setValue, possibleCurrencies, darkMode }: Props) {
  // function changeCurrency(currency: string) {
  //   setValue(currency);
  // }
  return (
    <Fragment>
      {possibleCurrencies.length === 1 ? null : (
        <div className={className}>
          <select
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={clsx(
              "focus:ring-theme-500 focus:border-theme-500 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base text-gray-800 focus:outline-none sm:text-sm",
              darkMode && "dark:border-gray-700 dark:bg-gray-800 dark:text-gray-50"
            )}
          >
            {possibleCurrencies.map((item) => {
              return (
                <option key={item} value={item}>
                  {item.toUpperCase()}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </Fragment>
  );
}
